<template>
  <MainCardsBlock
    v-element-visibility="(isVisibility) => isVisibility && sendHomeShowShelf(label, {
      shelf_number: shelfNumber,
    })"
    title="Онлайн-курсы от&nbsp;известных вузов"
    button-text="Смотреть курсы вузов"
    :button-link="`/catalog/?companies=${activevendorSlug}`"
    :tabs="uniTabsData"
    :cards="coursesByVendor"
    @click="handleVendorCoursesTabClick"
    @click:card="(event: any) => {
      sendHomeClickShelf(label, {
        shelf_number: shelfNumber
      })
      sendHomeClickCard({
        shelf_number: shelfNumber,
        card_number: event.cardNumber,
        nomenclature_id: event.nomenclatureId,
        nomenclature_type: event.nomenclatureType,
      })
    }"
    @click:link="sendHomeClickLink(label, {
      link: `/catalog/?companies=${activevendorSlug}`
    })"
    @visible:card="(event: any) => sendHomeShowCard({
      shelf_number: shelfNumber,
      card_number: event.cardNumber,
      nomenclature_id: event.nomenclatureId,
      nomenclature_type: event.nomenclatureType,
    })"
  />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { vElementVisibility } from '@vueuse/components'

  import { uniTabsData } from '@/pages/mocks'
  import { useMainStore } from '@/store/main'

  import { sendHomeShowShelf, sendHomeClickShelf, sendHomeClickCard, sendHomeClickLink, sendHomeShowCard } from '@/pages/index/analytics'

  const mainStore = useMainStore()
  const { coursesByVendor } = storeToRefs(mainStore)
  const activevendorSlug = ref(uniTabsData[0]?.slug || '')

  const label = 'university_courses'
  const shelfNumber = 4
  const promises: Promise<void>[] = []

  if (uniTabsData[0]?.slug) {
    promises.push(mainStore.getCoursesByVendor(uniTabsData[0].slug))
    activevendorSlug.value = uniTabsData[0].slug
  }

  useAsyncData(
    'getCoursesByVendor',
    () => Promise.all(promises)
  )

  const handleVendorCoursesTabClick = async (tab: { title: string; slug: string; }) => {
    activevendorSlug.value = tab.slug
    await mainStore.getCoursesByVendor(tab.slug)
  }
</script>
